import React from 'react'
import ParseContent from 'components/shared/ParseContent'
import styled from 'styled-components'
import { TextProps } from 'components/flex/Text/TextShell'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    @media (min-width: 1200px) {
      font-size: ${({ theme }) => theme.font.size.mega};
      line-height: ${({ theme }) => theme.font.size.mega};
      margin-bottom: 2rem;
    }
  }
  z-index: 4;
  position: relative;
`

const Text: React.FC<TextProps> = ({ fields }) => {
  return (
    <section>
      <div className="container py-5">
        <div className="row align-items-end justify-content-center">
          <div className={`col-lg-${fields.containerwidth}`}>
            <Content content={fields.description} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Text
